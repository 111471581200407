@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Inter", "Helvetica Neue", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply scroll-smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  height: 100%;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 300ms ease;
}

.underline-animation,
.blog-content a {
  position: relative;
  text-decoration: none;
}

.underline-animation::after,
.blog-content a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
  transform: scaleX(0);
}

.underline-animation:hover::after,
.blog-content a:hover::after {
  transform: scaleX(1);
}

.banner {
  padding: 160px 0;
}

.btn-primary {
  @apply rounded-full border font-bold text-xl py-3 px-10 transition-all inline-block w-fit
}